import React, { FunctionComponent, useEffect, useState } from 'react';
import { InputWithLabel, QueryLoaderWrapper } from '@companion-professional/components';
import { useCheckupUrl } from '../../../hooks/useCheckupUrl';
import { PimsSettingsProps } from './PimsSettingProps';
import { LanderUrl } from './LanderUrl';

// VirtualRecall displays the Virtual Recall PIMS settings.  Currently, this just includes a form to help with the
// creation of a sample the Pet Checkup URL that's used when sending a communication to a client.  Filling in the
// message id and secret will add them to the URL.
export const VirtualRecall: FunctionComponent<PimsSettingsProps> = ({ currentClinic }) => {
  const [landerUrl, setLanderUrl] = useState<string | null>(null);
  const [messageId, setMessageId] = useState('');
  const [messageSecret, setMessageSecret] = useState('');
  const { getCheckupLanderUrl, isPending, error } = useCheckupUrl();

  useEffect(() => {
    if (currentClinic?.slug) {
      setLanderUrl(`${getCheckupLanderUrl(currentClinic.slug)}?msg_id=${messageId}&msg_secret=${messageSecret}`);
    }
  }, [currentClinic, getCheckupLanderUrl, messageId, messageSecret]);

  return (
    <div>
      <div className="text-xl font-bold">Virtual Recall Integration</div>
      <div className="flex flex-row items-start gap-6 py-4">
        <div className="max-w-[500px]">
          <p className="font-bold">Checkup URL Creator</p>
          <p className="text-sm text-widget-foreground/50">
            This creates a link to the Virtual Recall message lander page. Enter in the message id and secret to
            generate the link.
          </p>
        </div>
        <div className="w-full min-w-72">
          <QueryLoaderWrapper isPending={isPending} error={error} size="small">
            <div className="flex flex-col gap-2">
              <InputWithLabel
                value={messageId}
                label="Message Id"
                description="Enter the message id that is generated in the Virtual Recall system"
                onChange={(e) => setMessageId(e.target.value)}
              />
              <InputWithLabel
                value={messageSecret}
                label="Message Secret"
                description="Enter the message secret that is generated in the Virtual Recall system"
                onChange={(e) => setMessageSecret(e.target.value)}
              />
            </div>
            {landerUrl ? (
              <div className="mt-4 border-t-2 pt-4">
                <div className="mb-2 text-base font-medium leading-none">Checkup Template URL</div>
                <LanderUrl landerUrl={landerUrl} clipboardCopySuccessMessage="Virtual Recall URL copied to clipboard" />
              </div>
            ) : null}
          </QueryLoaderWrapper>
        </div>
      </div>
    </div>
  );
};
