import React, { FunctionComponent, useEffect, useState } from 'react';
import { QueryLoaderWrapper } from '@companion-professional/components';
import { useCheckupUrl } from '../../../hooks/useCheckupUrl';
import { PimsSettingsProps } from './PimsSettingProps';
import { LanderUrl } from './LanderUrl';

// Shepherd displays the Shepherd PIMS settings.  This includes the copy-and-paste-able Pet Checkup Template URL (the
// url that is used in Shepherd communications to auto insert the pet ids so the lander can look up the pet's
// information).
export const Shepherd: FunctionComponent<PimsSettingsProps> = ({ currentClinic }) => {
  const [landerUrl, setLanderUrl] = useState<string | null>(null);
  const { getCheckupLanderUrl, isPending, error } = useCheckupUrl();

  useEffect(() => {
    if (currentClinic?.slug) {
      // The Shepherd communication system will replace the "{PATIENT IDS}" string with the actual patient ids when
      // sending the communication.
      setLanderUrl(getCheckupLanderUrl(currentClinic.slug) + '?p={PATIENT IDS}');
    }
  }, [currentClinic, getCheckupLanderUrl]);

  return (
    <div>
      <div className="text-xl font-bold">Shepherd PIMS</div>
      <div className="flex flex-row items-center gap-6 py-4">
        <div className="max-w-[500px]">
          <p className="font-bold">Checkup Template URL</p>
          <p className="text-sm text-widget-foreground/50">
            This is formatted link that can be used in a Shepherd Client Communication template. Copy and paste this
            link into your Shepherd Client Communication template. Shepherd will then automatically insert the needed
            patient ids into the link.
          </p>
        </div>
        <div className="w-full min-w-72">
          <QueryLoaderWrapper isPending={isPending} error={error} size="small">
            {landerUrl ? (
              <LanderUrl
                landerUrl={landerUrl}
                clipboardCopySuccessMessage="Shepherd pet checkup template URL copied to clipboard"
              />
            ) : null}
          </QueryLoaderWrapper>
        </div>
      </div>
    </div>
  );
};
