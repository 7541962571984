import { FunctionComponent } from 'react';
import { PimsType } from '@companion-professional/dctypes';
import { PimsSettingsProps } from './PimsSettingProps';
import { Shepherd } from './Shepherd';
import { VirtualRecall } from './VirtualRecall';
import { Fake } from './Fake';

export const pimsSettingsComponents: Record<PimsType, FunctionComponent<PimsSettingsProps>> = {
  shepherd: Shepherd,
  virtualrecall: VirtualRecall,
  fakepims: Fake
};
