import React, { FunctionComponent } from 'react';
import { pimsSettingsComponents } from './pims';
import { PimsSettingsProps } from './pims/PimsSettingProps';

export const PimsSettings: FunctionComponent<PimsSettingsProps> = ({ currentClinic }) => {
  if (!currentClinic || !currentClinic.pims_type) {
    // Clinic does not have a PIMS set up
    return null;
  }

  const PimsComponent = pimsSettingsComponents[currentClinic.pims_type];
  if (!PimsComponent) {
    // We don't have a settings component for the provided PIMS, so just display the PIMS name.  This might happen if
    // a new PIMS is added on the backend, but we haven't added support for it yet in the UI; so we also log an error.
    console.error(`No PIMS settings component found for ${currentClinic.name} - ${currentClinic.pims_type}`);
    return (
      <div>
        <div className="text-xl font-bold">PIMS Enabled: {currentClinic.pims_type}</div>
      </div>
    );
  }

  return <PimsComponent currentClinic={currentClinic} />;
};
