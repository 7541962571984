import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { PetAndClinicInfo } from '@companion-professional/dctypes';
import { ColumnHeaderCycleSort } from '@companion-professional/components';

export const getPetsForClinicColumns = (onOpen: (pet: PetAndClinicInfo) => void): ColumnDef<PetAndClinicInfo>[] => {
  return [
    {
      accessorKey: 'name',
      header: ({ column }) => <ColumnHeaderCycleSort column={column} title="Pet Name" />,
      cell: ({ row }) => <div onClick={() => onOpen(row.original)}>{row.getValue('name')}</div>
    },
    {
      accessorKey: 'clinic_pet_id',
      header: ({ column }) => <ColumnHeaderCycleSort column={column} title="Pet ID" />,
      cell: ({ row }) => <div onClick={() => onOpen(row.original)}>{row.getValue('clinic_pet_id')}</div>,
      meta: {
        hideWhenMd: true
      }
    }
  ];
};
