import React, { FunctionComponent } from 'react';
import { ClinicInfo } from '@companion-professional/dctypes';
import { Page } from '../../components/Page';
import { PimsSettings } from './PimsSettings';

interface SettingsProps {
  currentClinic?: ClinicInfo;
}

export const Settings: FunctionComponent<SettingsProps> = ({ currentClinic }) => {
  return <Page title="Settings">{currentClinic ? <PimsSettings currentClinic={currentClinic} /> : null}</Page>;
};
