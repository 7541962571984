import React, { FunctionComponent } from 'react';
import { Copy } from 'lucide-react';
import {
  buttonVariants,
  Input,
  toast,
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from '@companion-professional/components';
import { cn } from '@companion-professional/webutils';

interface LanderUrlProps {
  landerUrl: string;

  // clipboardCopySuccessMessage is the toast message that is displayed when the URL is successfully copied to the
  // clipboard.
  clipboardCopySuccessMessage: string;
}

// LanderUrl is a component that displays the URL (landerUrl) for the clinic lander page and allows the user to copy it
// to their clipboard.
export const LanderUrl: FunctionComponent<LanderUrlProps> = ({ landerUrl }) => {
  return (
    <div className="flex flex-1 flex-row gap-2">
      <Input value={landerUrl} className="w-full flex-1" readOnly />
      <Tooltip>
        <TooltipTrigger>
          <div
            className={cn('px-3', buttonVariants({ variant: 'primary', size: 'icon' }))}
            onClick={async () => {
              try {
                await navigator.clipboard.writeText(landerUrl);
                toast('', {
                  description: landerUrl
                });
              } catch (err) {
                const msg = `Could not copy text "${landerUrl}" to clipboard`;
                console.error(msg, err);
              }
            }}
          >
            <Copy size="18" />
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <p>Copy the Pet Checkup Template URL to your clipboard.</p>
        </TooltipContent>
      </Tooltip>
    </div>
  );
};
